.App {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;
  max-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-pic {
  height: 40%;
  width: auto;
  min-width: 320px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}

.question {
  display: inline-block;
  padding: 10px 20px 10px;
  background: #000000a3;
  font-size: 1.5rem;
}

.answers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 60%;
  max-height: 52vh;
}

.answer {
  padding: 5px;
  background-color: #0cbaba;
  background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%);
  max-width: 85%;
  border-radius: 55px;
  border: 1px solid #f5f5f5;
  height: 47px;
  width: 100%;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.answer.clicked {
  background-color: #5de6de;
  background-image: linear-gradient(315deg, #5de6de 0%, #b58ecc 74%);
}

.answer.clicked.wrong {
  background-color: #bd4f6c;
  background-image: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%);
}

.answer.clicked.correct {
  background-color: #3bb78f;
  background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
}

.answer p {
  width: 100%;
}

.App-link {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 1.1rem;
  padding: 5px;
  border: 1px solid #61dafb;
  border-radius: 5px;
  color: #61dafb;
}